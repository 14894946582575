<template>
    <div>
      <router-view v-show="this.$route.matched.length===3"></router-view>
      <PageHeaderLayout v-show="this.$route.matched.length===2">
        <div class="main-page-content">
          <div class="default-table">
            <el-row>
              <i @click="getList" class="el-icon-refresh" style="cursor: pointer;margin: 10px 10px"></i>
            </el-row>
            <el-row>
              <el-col :span="3">
                <el-button @click="kolApprove" type="primary">创建/修改推广投放付款申请</el-button>
              </el-col>

              <el-col :span="3">
                <el-button @click="normarlReimbursement" type="primary">创建/修改通用报销申请</el-button>
              </el-col>
              <el-col :span="3">
                <el-button @click="Payment" type="primary">创建/修改通用付款申请</el-button>
              </el-col>
            </el-row>
            <el-row style="margin: 15px 0;">
              <el-col :span="3">
                <el-button @click="kolExpense" type="primary">创建/修改红人费用报销申请</el-button>
              </el-col>
              <el-col :span="3">
                <el-button @click="pettyCash" type="primary">创建/修改备用金申请</el-button>
              </el-col>
              <el-col :span="3">
                <el-button @click="saveTravel" type="primary">创建/修改差旅报销申请</el-button>
              </el-col>

            </el-row>

            <el-table
                :border="true"
                :data="tableData"
                ref="multipleTable"
                row-key="id"
                style="width: 100%"
                tooltip-effect="dark"
                v-if="userPermissions.indexOf('oa_tpl_list') !== -1"
            >
              <el-table-column
                  align="center"
                  label="ID"
                  prop="id"
                  width="80"
              ></el-table-column>
              <el-table-column
                  align="center"
                  label="模板名称"
                  prop="name"
                  width="200"
              >
              </el-table-column>
              <el-table-column
                  align="center"
                  label="描述"
                  prop="description"
              >
              </el-table-column>
              <el-table-column
                  align="center"
                  label="编号"
                  min-width="180"
                  prop="process_code"
              >
              </el-table-column>
              <el-table-column
                  align="center"
                  label="创建时间"
                  prop="created_at"
              >
              </el-table-column>
              <el-table-column
                  align="center"
                  label="更新时间"
                  prop="updated_at"
              >

              </el-table-column>
              <el-table-column
                  align="center"
                  label="操作"
                  width="100">
                <template slot-scope="{row}">

                  <el-button @click="normarlReimbursement('update',row.process_code)"
                             key="artist_divide_item"
                             size="small"
                             style="margin-left: 6px" type="text"
                  >
                    更新
                  </el-button>

                </template>
              </el-table-column>
            </el-table>
            <br/>
            <br/>
            <el-form :inline="true" label-position="right" v-model="searchStatus">
              <el-form-item>
                <el-date-picker
                    @change="handlerQuery"
                    v-model="pickerTime"
                    type="month"
                    value-format="yyyy-MM"
                    placeholder="选择月">
                </el-date-picker>
              </el-form-item>
            </el-form>
            <Chart :dataList="dataList" :title="'涨粉'" v-if="isHiddenbtn"></Chart>

          </div>

        </div>

      </PageHeaderLayout>
    </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import { mapGetters } from 'vuex'
import Chart from './chart.vue'

export default {
  name: 'PublicationIndex',
  components: { PageHeaderLayout, Chart },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo'])

  },
  data() {
    return {
      // 数组data
      tableData: [],
      isHiddenbtn: true,
      dataList: [],
      //筛选data
      searchStatus: {
        code: ''
      },
      optionsValue: '',
      options: [
        { value: 1, label: '推广投放申请' },
        { value: 2, label: '红人费用申请' },
        { value: 3, label: '通用付款申请' },
        { value: 4, label: '通用报申请' },
        { value: 5, label: '备用金申请' },
        { value: 6, label: '差旅报销申请' }
      ],
      pickerTime: ''

    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async Payment() {

      let id = await this.$api.normarlPayment()
      if (id) {
        this.$notify.success('操作成功')
        await this.getList()
      } else this.$notify.error('操作失败')
    },
    async normarlReimbursement(value, code) {
      let id = ''
      if (value === 'update') {
        id = await this.$api.normarlReimbursement({
          'process_code': code
        })
      } else {
        id = await this.$api.normarlReimbursement()
      }
      if (id) {
        this.$notify.success('操作成功')
        await this.getList()

      } else this.$notify.error('操作失败')
    }
    ,
    async pettyCash() {

      let id = await this.$api.pettyCash()
      if (id) {
        this.$notify.success('操作成功')
        await this.getList()

      } else this.$notify.error('操作失败')
    }
    ,
    async kolApprove() {

      let id = await this.$api.kolApprove()
      if (id) {
        this.$notify.success('操作成功')
        await this.getList()

      } else this.$notify.error('操作失败')
    }
    ,

    async kolExpense() {

      let id = await this.$api.kolExpense()
      if (id) {
        this.$notify.success('操作成功')
        await this.getList()

      } else this.$notify.error('操作失败')
    },
    async saveTravel() {

      let id = await this.$api.saveTravel()
      if (id) {
        this.$notify.success('操作成功')
        await this.getList()

      } else this.$notify.error('操作失败')
    },
    handlerQuery() {
      this.getList()
    },
    searchCondition() {
      let searchCondition = {}
      if ((this.pickerTime || undefined) !== undefined) {
        searchCondition['year'] = Number(this.pickerTime.slice(0, 4))
        searchCondition['month'] = Number(this.pickerTime.slice(5, this.pickerTime.length))
      }
      return searchCondition
    },
    async getList() {
      let searchCondition = this.searchCondition()
      let { list } = await this.$api.oaTemplateList()
      let data = await this.$api.countTemplateList(searchCondition)
      this.tableData = list
      this.dataList = data
    }
  }
  }
</script>
<style lang="scss">
    #foo > .el-checkbox__input.is-checked .el-checkbox__inner, .foo > * > .el-checkbox__input.is-checked .el-checkbox__inner {
        background-color: #409EFF;
        border: 1px solid #409EFF;

    }

    #foo > * > .el-checkbox__inner:hover, .foo > * > * > .el-checkbox__inner:hover {
        border-color: #409EFF !important;
    }

    .foo > * > span:last-child {
        display: none;
    }
</style>