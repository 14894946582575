<template>
  <div id="main" ref="bar_dv" style="width: 100%;height:400px;margin-top: 20px;"></div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  name: 'LineChart',
  props: {
    dataList: {
      type: Array
    }
  },
  data() {
    return {
      chartOption: {}

    }
  },
  watch: {
    dataList: {
      deep: true,
      handler() {
        this.getChartDataRevenue()
      }
    }
  },
  methods: {
    async getChartDataRevenue() {
      // let chartDom = document.getElementById('main')
      let myChart = echarts.init(this.$refs.bar_dv)
      let chartOption = {
        title: {
          text: 'OA审批数据展示',
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        }
      }
      let newOption = {
        name: 'OA数据统计',
        type: 'pie',
        radius: [20, 140],
        roseType: 'area',
        itemStyle: {
          borderRadius: 5
        },
        data: this.dataList
      }
      chartOption.series = [newOption]
      myChart.setOption(chartOption)
    }

  },
  mounted() {
    this.getChartDataRevenue()
  },
  update() {
    this.getChartDataRevenue()

  }
}
</script>

<style scoped>


</style>